import React from "react"
import { ReactSVG } from "react-svg"
import filterIcon from "../../images/blog/black-filter-icon.svg"
import { useBlogConfiguration } from "./hooks"
import { ArticlePreview, DesktopOptionsFilter } from "./components"
import {
  StyledBlogWrappper,
  StyledBlogTitle,
  StyledBlogFilterContainer,
  StyledBlogArticlesWrapper,
  StyledTabletAndMobileFilterButton,
  StyledTabletAndMobileFilterButtonText,
} from "./styled"

const Blog = ({ data: { allStrapiArticle: { edges }, ...categories} }) => {
  const { isMounted, isDesktop, filteredOptions, openMobileFiltersSection } = useBlogConfiguration(edges, categories)

  return isMounted && (
    <StyledBlogWrappper>
      <StyledBlogTitle>Blog</StyledBlogTitle>
      <StyledBlogFilterContainer>
        {isDesktop
        ? <DesktopOptionsFilter />
        : !!filteredOptions.length && ( 
        <StyledTabletAndMobileFilterButton onClick={openMobileFiltersSection}>
            <ReactSVG src={filterIcon} />
            <StyledTabletAndMobileFilterButtonText>
              Filters
            </StyledTabletAndMobileFilterButtonText>
        </StyledTabletAndMobileFilterButton>
        )}
      </StyledBlogFilterContainer>
      <StyledBlogArticlesWrapper>
        {filteredOptions?.map(props => <ArticlePreview {...props} />)}
      </StyledBlogArticlesWrapper>
    </StyledBlogWrappper>
  )
}

export default Blog
